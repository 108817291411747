(function ($) {
  DJ.addBehavior('touchScroll', '.js-touch-scroll', {
    attach: function () {
      const ele = this;
      const $links = $(ele).find('a');
      let pos = {
        top: 0, left: 0, x: 0, y: 0
      };

      const mouseMoveHandler = function (e) {
        ele.style.pointerEvents = 'none';
        if ($links.css('position') === 'absolute') {
          $links.hide();
        }

        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = function () {
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
        ele.style.removeProperty('pointer-events');
        $links.show();

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      const mouseDownHandler = function (e) {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      const initDragScroll = function () {
        if (ele && !DJ.isTouchScreen) {
          ele.style.cursor = 'grab';
          ele.addEventListener('mousedown', mouseDownHandler);
        }
      };

      const destroyDragScroll = function () {
        if (ele) {
          ele.style.cursor = '';
          ele.removeEventListener('mousedown', mouseDownHandler);
        }
      };

      if (window.innerWidth <= 1200) {
        // Attach drag scroll for non touch devices.
        initDragScroll();
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth > 1200) {
          destroyDragScroll();
        } else {
          initDragScroll();
        }
      });
    }
  });
})(jQuery);
