(function ($) {
  DJ.addBehavior('filtersUnits', '.product-filter-items', {
    attach: function () {
      const mainHolder = this;
      const filters = mainHolder.querySelectorAll('.product-filter-item');
      const unitsRadios = mainHolder.querySelectorAll('.js-product-filter-item-units input');

      if (!filters && !unitsRadios) {
        return;
      }

      /**
       * Display/hide items.
       * @param item
       *  Element object.
       * @param {string} toggleUsItem
       *  Style display value.
       * @param {string} toggleMetricsItem
       *  Style display value.
       */
      const onRadioChange = (item, toggleUsItem, toggleMetricsItem) => {
        const filterItem = item;

        if (filterItem.classList.contains('product-filter-item--metrics')) {
          filterItem.style.display = toggleMetricsItem;
        }

        if (filterItem.classList.contains('product-filter-item--us')) {
          filterItem.style.display = toggleUsItem;
        }
      };

      /**
       * On init set value from the localStorage.
       * If the localStorage is empty or there are no search parameters in the URL
       * set 'metrics' by default.
       * @param element
       *  Radio element.
       */
      const onRadioInit = (element) => {
        const radioElement = element;
        const valueOnInit = localStorage.getItem('product-filter-item-units-value');
        const urlSearchParams = window.location.search;

        if (urlSearchParams.length === 0) {
          if (radioElement.value === 'metrics') {
            radioElement.checked = true;
          }

          return;
        }

        if (valueOnInit && radioElement.value === valueOnInit) {
          radioElement.checked = true;
        }

        if (!valueOnInit && radioElement.value === 'metrics') {
          radioElement.checked = true;
        }
      };

      /**
       * Check which items should be displayed depending on the checked radio.
       * @param {Array} filtersArr
       *  Filter items.
       * @param element
       *  Radio element.
       */
      const toggleFilters = (filtersArr, element) => {
        if (!element.checked) {
          return;
        }

        switch (element.value) {
          case 'metrics':
            filtersArr.forEach((filterItem) => {
              onRadioChange(filterItem, 'none', 'block');
            });
            localStorage.setItem('product-filter-item-units-value', 'metrics');
            break;
          case 'us':
            filtersArr.forEach((filterItem) => {
              onRadioChange(filterItem, 'block', 'none');
            });
            localStorage.setItem('product-filter-item-units-value', 'us');
            break;
          default:
            break;
        }
      };

      /**
       * On change display/hide items depending on choice.
       */
      const unitsRadiosArr = Array.prototype.slice.call(unitsRadios);
      const filtersArr = Array.prototype.slice.call(filters);

      unitsRadiosArr.forEach((e) => {
        onRadioInit(e);
        toggleFilters(filtersArr, e);

        e.addEventListener('change', () => {
          toggleFilters(filtersArr, e);
        });
      });
    }
  });
})(jQuery);
