(function ($) {
  DJ.addBehavior('resFilters', '.views-display-switch:not(.map-section .views-display-switch)', {
    attach: function () {
      const introBlock = document.querySelector('.intro-block');

      if (introBlock) {
        introBlock.classList.add('intro-block--with-filters');
      }
    }
  });
})(jQuery);
