(function ($) {
  DJ.addBehavior('tmChar', 'sup', {
    attach: function () {
      const sup = this;

      if (sup.innerText.toUpperCase() === 'TM') {
        sup.classList.add('sup-with-tm');
        sup.innerHTML = '&trade;';
      }
    }
  });
})(jQuery);
