(function ($) {
  DJ.addBehavior('itemsSlider', '.related-products.with-slider', {
    attach: function (context, settings) {
      const $slickElement = $(this).find('.related-products__list');
      const $status = $('.slider-info');
      const $currentPage = $status.find('.page-current');
      const $counterPage = $status.find('.page-counter');
      const $buttonPrev = $status.find('.btn-prev');
      const $buttonNext = $status.find('.btn-next');
      const $sliderProgress = $status.find('.slider-progress');

      $slickElement.on('init reInit afterChange', function (event, slick) {
        if (!slick.$dots) {
          return;
        }

        $status.addClass('slider-info--visible');

        const $activePage = slick.$dots.find('.slick-active').text();
        const $counter = slick.$dots[0].children.length;
        const $preNumber = '0';
        if ($activePage < 10) {
          $currentPage.text($preNumber + $activePage);
        } else {
          $currentPage.text($activePage);
        }
        $counterPage.text($counter);
      });

      $slickElement.slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        appendDots: $sliderProgress,
        variableWidth: true,
        prevArrow: $buttonPrev,
        nextArrow: $buttonNext,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    },
    detach: function (context, settings) {}
  });
})(jQuery);
