(function ($) {
  DJ.addBehavior('downloadSelect', '.download-data', {
    attach: function () {
      const $downloadHolder = $(this);
      const $downloadSelect = $downloadHolder.find('.download-select');
      const $dropDownButton = $downloadSelect.find('.download-select__button');
      const $dropDown = $downloadSelect.find('.download-select__list');
      const $dropDownlist = $dropDown.find('li');
      const $dropDownitems = $dropDownlist.find('a');
      const dropDownShowClass = 'download-select__list--show';

      if (!$dropDownButton && !$dropDown) {
        return;
      }

      $dropDownlist.first().find('a').addClass('is-active');

      function changeText() {
        if ($dropDownitems.hasClass('is-active')) {
          const $activeItem = $dropDown.find('.is-active');
          const $downloadName = $downloadHolder.find('.download-name span');
          const $downloadView = $downloadHolder.find('.download-view a');
          const $downloadFile = $downloadHolder.find('.download-file a');
          $dropDownButton.text($activeItem.text());
          $downloadName.text($activeItem.attr('data-name'));
          $downloadView.attr('href', $activeItem.attr('data-link'));
          $downloadFile.attr('href', $activeItem.attr('data-link'));
        }
      }
      changeText();

      $dropDownitems.on('click', function () {
        $dropDownitems.removeClass('is-active');
        $(this).addClass('is-active');
        changeText();
        return false;
      });

      const toggleDropDown = () => {
        $dropDown.toggleClass(dropDownShowClass);
      };

      $downloadSelect.on('mouseover', toggleDropDown);
      $downloadSelect.on('mouseout', toggleDropDown);
    }
  });
})(jQuery);
