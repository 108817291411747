(function ($) {
  DJ.addBehavior('homeSlider', '.homepage-slider', {
    attach: function (context, settings) {
      const $imgSlider = $(this).find('.homepage-slider__image');
      const $switcher = $(this).find('.header-list');
      const $dataSlider = $(this).find('.content-list__holder');
      const $dataBtnPrev = $(this).find('.content-list .buttons .btn-prev');
      const $dataBtnNext = $(this).find('.content-list .buttons .btn-next');
      const $switcherBtnPrev = $(this).find('.header-list .btn-prev');
      const $switcherBtnNext = $(this).find('.header-list .btn-next');

      $imgSlider.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        speed: 500,
        fade: true,
        adaptiveHeight: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000
      });
    },
    detach: function (context, settings) {}
  });

  DJ.addBehavior('videoPopup', '.play', {
    attach: function (context, settings) {
      const $btnOpen = $(this);
      const $videoHolder = $btnOpen.parents('.homepage-slider');
      const $popup = $videoHolder.find('.video-popup');
      const $btnClose = $popup.find('.btn-close');
      const $video = $popup.find('.video');
      const $dataSlider = $videoHolder.find('.content-list__holder');

      $btnOpen.on('click', function () {
        const $videoElement = $(this).closest('.img-holder').find('.img-holder__video').html();
        $dataSlider.slick('slickPause');

        $video.append($videoElement);

        const $videoPlyr = $video.find('video');

        if ($videoPlyr.length) {
          const options = {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
          };

          /**
           * Init player.
           */
          const player = new Plyr($videoPlyr, options);
        }

        $popup.css('display', 'block');
        $('body').addClass('modal-open');
      });

      $btnClose.on('click', function () {
        $video.html('');
        $popup.css('display', 'none');
        $dataSlider.slick('slickPlay');
        $('body').removeClass('modal-open');
      });
    },
    detach: function (context, settings) {}
  });
})(jQuery);
