(function ($) {
  DJ.addBehavior('latestNewsVideo', '.latest-news-video', {
    attach: function () {
      const holder = this;
      const background = holder.querySelector('.latest-news-video__bg');
      const newsHolder = holder.querySelector('.latest-news-video__holder');
      const image = holder.querySelector('.latest-news-block__img');

      if (!background || !newsHolder || !image) {
        return;
      }

      // Calculate height for the latest news background.
      DJ.behaviors.calculateNewsBg.calculate(image, newsHolder, background);
    }
  });
})(jQuery);
