(function ($) {
  DJ.addBehavior('mediaSlider', '.media-slider', {
    attach: function () {
      const sliderHolder = this;
      const slider = sliderHolder.querySelector('.media-slider__slider');
      const sliderThumbnails = sliderHolder.querySelector('.media-slider__thumbnails');
      const $slider = $(slider);
      const $sliderThumbnails = $(sliderThumbnails);

      if (!slider && !sliderThumbnails) {
        return;
      }

      /**
       * Slider options.
       */
      const sliderOptions = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        asNavFor: '.media-slider__thumbnails'
      };

      const sliderThumbnailsOptions = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: `<button type="button" class="slick-prev">
                    </button>`,
        nextArrow: `<button type="button" class="slick-next">
                    </button>`,
        asNavFor: '.media-slider__slider',
        focusOnSelect: true
      };

      /**
       * Slider init function with setting the equal height to the needed elements.
       */
      const sliderInit = () => {
        $slider.slick(sliderOptions);
        $sliderThumbnails.slick(sliderThumbnailsOptions);
      };

      sliderInit();
    }
  });
})(jQuery);
