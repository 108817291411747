(function ($) {
  DJ.addBehavior('resultsReset', '.map-contacts__inner', {
    attach: function () {
      const holder = this;
      const resetButton = holder.querySelector('[data-drupal-selector="edit-reset"]');
      if (!resetButton) {
        return;
      }

      /**
       * Init fake reset button.
       */
      const fakeResetButton = document.querySelector('.map-contacts__results-amount span');
      if (fakeResetButton) {
        // On click on the fake reset click on the real reset button.
        fakeResetButton.addEventListener('click', () => {
          resetButton.click();
        });
      }
    }
  });
})(jQuery);
