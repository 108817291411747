(function ($) {
  DJ.addBehavior('tooltip', '.tooltip', {
    attach: function () {
      const tooltip = this;
      const tooltipContent = tooltip.querySelector('.tooltip__content');
      const mainWrapper = document.querySelector('.map-contacts');

      if (!mainWrapper || !tooltipContent) {
        return;
      }

      // On hover check the tooltip position, and move it if it's off-screen.
      tooltip.addEventListener('mouseenter', () => {
        const mainWrapperRightPos = mainWrapper.getBoundingClientRect().left
          + mainWrapper.offsetWidth;
        const tooltipContentRightPos = tooltipContent.getBoundingClientRect().left
          + tooltipContent.offsetWidth;

        if (tooltipContentRightPos >= mainWrapperRightPos) {
          const positionDifference = tooltipContentRightPos - mainWrapperRightPos;
          tooltipContent.style.left = `${-((tooltipContent.offsetWidth / 2) + positionDifference)}px`;
        }
      });

      tooltip.addEventListener('mouseleave', () => {
        tooltipContent.style.left = '';
      });
    }
  });
})(jQuery);
