(function ($) {
  DJ.addBehavior('technicalBlock', '.technical-block li', {
    attach: function () {
      const listElement = this;
      const value = listElement.querySelector('.value div');
      const valueChild = listElement.querySelector('.value div div');

      if (!value) {
        return;
      }

      if (value.innerText.length > 12) {
        listElement.classList.add('technical-block-in-two-lines');
      }

      if (value.contains(valueChild)) {
        listElement.classList.remove('technical-block-in-two-lines');
      }
    }
  });
})(jQuery);
