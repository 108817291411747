(function ($) {
  DJ.addBehavior('textSeeMore', '.text-see-more', {
    attach: function () {
      const textSeeMoreWrapper = this;
      const buttonMoreLess = textSeeMoreWrapper.querySelector('.text-see-more__button');
      const buttonMoreLessActiveClass = 'text-see-more__button--active';
      const truncatedClass = 'text-see-more--truncated';

      const truncate = (lines, textWrapEl, textHeight, textLineHeight) => {
        const textWrapElement = textWrapEl;
        const isTruncate = textHeight / textLineHeight >= lines;

        if (isTruncate) {
          if (buttonMoreLess) {
            buttonMoreLess.style.display = 'flex';
          }
          textWrapElement.classList.add(truncatedClass);
          textWrapElement.style.height = `${lines * textLineHeight}px`;
        } else if (!isTruncate) {
          if (buttonMoreLess) {
            buttonMoreLess.style.display = 'none';
          }
          textWrapElement.style.height = 'auto';
        }
      };

      const calculateAndTruncate = () => {
        const textWrapEl = textSeeMoreWrapper.querySelector('.text-see-more__text');

        if (!textWrapEl) {
          return;
        }

        const textLineHeight = parseInt(
          document.defaultView
            .getComputedStyle(textWrapEl, null)
            .getPropertyValue('line-height'),
          10
        );
        const afterElementHeight = parseInt(
          window
            .getComputedStyle(textWrapEl, ':after')
            .getPropertyValue('line-height'),
          10
        );

        const textHeight = textWrapEl.clientHeight - afterElementHeight;

        truncate(6, textWrapEl, textHeight, textLineHeight);
      };

      const removeTruncate = () => {
        const textWrapEl = textSeeMoreWrapper.querySelector('.text-see-more__text');

        textWrapEl.classList.remove(truncatedClass);
        textWrapEl.style.height = 'auto';
      };

      calculateAndTruncate();

      if (buttonMoreLess) {
        buttonMoreLess.addEventListener('click', () => {
          const textWrapEl = textSeeMoreWrapper.querySelector('.text-see-more__text');

          if (textWrapEl.classList.contains(truncatedClass)) {
            removeTruncate();
            buttonMoreLess.classList.add(buttonMoreLessActiveClass);
          } else {
            calculateAndTruncate();
            buttonMoreLess.classList.remove(buttonMoreLessActiveClass);
          }
        });
      }

      window.addEventListener('resize', function () {
        removeTruncate();
        calculateAndTruncate();

        if (buttonMoreLess) {
          buttonMoreLess.classList.remove(buttonMoreLessActiveClass);
        }
      });
    },
  });
})(jQuery);
