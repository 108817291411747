(function ($) {
  DJ.addBehavior('headerNavigation', '.header-navigation', {
    attach: function () {
      const $navigation = $(this);
      const $openMenuBtn = $navigation.find('.header-navigation__menu-btn');
      const $openMenuBtnMobile = $navigation.find('.header-navigation__menu-btn-arrow');
      const $allSections = $navigation.find('.header-navigation__below');
      const $firstNavBelow = $navigation.find('.header-navigation__first-level > li > .header-navigation__below');
      const linkActiveClass = 'header-navigation__menu-btn--active';
      const menuOpenedClass = 'header-navigation__below--opened';
      const level2OpenedClass = 'header-navigation__below-level-1--level-2-opened';
      const $body = $('body');
      const headerOverlayStructure = '<div class="header-overlay"></div>';
      const headerOverlayClass = 'header-overlay--show';
      const openEvent = document.getElementsByTagName('html')[0].classList.contains('dj-touch')
        ? 'click' : 'mouseover';

      // Add overlay to the body.
      if (!document.querySelector('.header-overlay')) {
        $body.append(headerOverlayStructure);
      }

      // Hide overlay.
      const headerOverlay = document.querySelector('.header-overlay');
      headerOverlay.classList.remove(headerOverlayClass);

      /**
       * Set max height for navigation section.
       */
      const setMaxHeight = (navToSetHeight, elements) => {
        let maxHeight = -1;
        const navSection = navToSetHeight[0];
        const elementsArr = Array.prototype.slice.call(elements);

        $navigation[0].classList.add('js-header-navigation-below-calculate-height');

        elementsArr.forEach((e) => {
          maxHeight = Math.max(maxHeight, e.offsetHeight);
        });

        $navigation[0].classList.remove('js-header-navigation-below-calculate-height');

        navSection.style.height = `${maxHeight}px`;
      };

      /**
       * Close navigation dropdown.
       */
      const closeDropDown = () => {
        headerOverlay.classList.remove(headerOverlayClass);
        $allSections.removeClass(menuOpenedClass);
        $allSections.removeClass(level2OpenedClass);
        $openMenuBtn.removeClass(linkActiveClass);
        $openMenuBtnMobile.removeClass(linkActiveClass);
        $firstNavBelow.height('auto');
        $('body').off(`${openEvent}.bodyCloseHeaderNavigation`);
      };

      /**
       * Close navigation dropdown on body click.
       */
      const onBodyClose = () => {
        $('body').on(`${openEvent}.bodyCloseHeaderNavigation`, (e) => {
          if (DJ.bp.name === 'desktopBig') {
            if (!($(e.target).closest('.header-navigation__menu-btn').length)
              && !($(e.target).closest('.header-navigation__below').length)) {
              closeDropDown();
            }
          }
        });
      };

      /**
       * Open header navigation dropdown.
       */
      const openDropDown = function () {
        const thisBtn = this;

        if (!thisBtn.classList.contains(linkActiveClass)) {
          const $closestUl = $(thisBtn).closest('ul');
          const $closestLi = $(thisBtn).closest('li');

          onBodyClose();

          if ($closestUl.hasClass('header-navigation__first-level')) {
            $openMenuBtn.removeClass(linkActiveClass);
            $openMenuBtnMobile.removeClass(linkActiveClass);
            $allSections.removeClass(menuOpenedClass);
            $allSections.removeClass(level2OpenedClass);
          } else {
            $closestUl.find('> li > .header-navigation__below').removeClass(menuOpenedClass);
            $closestUl.find('> li > .header-navigation__menu-btn').removeClass(linkActiveClass);
            $closestUl.find('> li > .header-navigation__menu-btn-arrow').removeClass(linkActiveClass);
          }

          const $closestNav = $closestLi.closest('li').find('> .header-navigation__below');

          $closestNav.addClass(menuOpenedClass);
          thisBtn.classList.add(linkActiveClass);
          headerOverlay.classList.add(headerOverlayClass);

          if ($closestUl.hasClass('header-navigation__first-level')) {
            $firstNavBelow.height('auto');

            if (DJ.bp.name === 'desktopBig') {
              setMaxHeight($firstNavBelow, $closestLi[0].querySelectorAll('.header-navigation__below'));
            }
          }

          if (DJ.bp.name !== 'desktopBig' && $closestUl.hasClass('header-navigation__below-level-1')) {
            $closestUl.addClass(level2OpenedClass);
          }
        } else if (DJ.bp.name !== 'desktopBig') {
          const $closestUl = $(thisBtn).closest('ul');

          $closestUl.find('> li > .header-navigation__below').removeClass(menuOpenedClass);
          $closestUl.find('> li > .header-navigation__menu-btn').removeClass(linkActiveClass);
          $closestUl.find('> li > .header-navigation__menu-btn-arrow').removeClass(linkActiveClass);
          $closestUl.removeClass(level2OpenedClass);
        }
      };

      const openButtonsBind = () => {
        if (DJ.bp.name === 'desktopBig') {
          $openMenuBtn.on(openEvent, openDropDown);
          $openMenuBtnMobile.off(openEvent, openDropDown);
        } else {
          $openMenuBtn.off(openEvent, openDropDown);
          $openMenuBtnMobile.on(openEvent, openDropDown);
        }
      };

      /**
       * Close navigation dropdown on breakpoint change.
       * Change the open/close events.
       */
      DJ.on('changeBreakpoint', () => {
        closeDropDown();
        openButtonsBind();
      });

      openButtonsBind();
    }
  });
})(jQuery);
