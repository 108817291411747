(function ($) {
  DJ.addBehavior('viewMore', '.technical-section', {
    attach: function (context, settings) {
      const $hidenBlock = $(this).find('.more-items');
      const $btnMore = $(this).find('.view-more');
      const $btnLess = $(this).find('.view-less');

      $btnMore.on('click', function () {
        $(this).stop().hide();
        $btnLess.show().css('display', 'inline-block');
        $hidenBlock.stop().slideDown().css('display', 'flex');
        return false;
      });

      $btnLess.on('click', function () {
        $(this).stop().hide();
        $btnMore.stop().show().css('display', 'inline-block');
        $hidenBlock.stop().slideUp();
        return false;
      });
    },
    detach: function (context, settings) {}
  });
})(jQuery);
