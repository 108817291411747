(function ($) {
  DJ.addBehavior('inputLabels', '.contact-form .form-item', {
    attach: function () {
      const formItem = this;
      const formInput = formItem.querySelector('[type=\'text\'], [type=\'tel\'], [type=\'email\'], textarea');
      const formItemFocusClass = 'form-item--in-focus';

      if (!formInput) {
        return;
      }

      if (formInput.value.length !== 0) {
        formItem.classList.add(formItemFocusClass);
      }

      formInput.addEventListener('focus', () => {
        formItem.classList.add(formItemFocusClass);
      });

      formInput.addEventListener('blur', () => {
        if (formInput.value.length === 0) {
          formItem.classList.remove(formItemFocusClass);
        }
      });
    }
  });
})(jQuery);
