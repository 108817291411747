(function ($) {
  DJ.addBehavior('calculateNewsBg', {
    calculate: function (image, holder, background) {
      const bgToUpdate = background;

      // Calculate height for the latest news background.
      const calculateBgHeight = () => {
        const imageHeight = image.offsetHeight;
        const imageTop = image.getBoundingClientRect().top;
        const newsHolderTop = holder.getBoundingClientRect().top;

        bgToUpdate.style.height = `${Math.abs(newsHolderTop - (imageTop + imageHeight))}px`;
      };

      window.addEventListener('load', () => {
        calculateBgHeight();
      });

      window.addEventListener('resize', () => {
        setTimeout(() => {
          calculateBgHeight();
        }, 300);
      });
    }
  });
})(jQuery);
