(function ($) {
  DJ.addBehavior('stckyMenu', '#main', {
    attach: function (context, settings) {
      const $bodyHtml = $('html, body');
      const $itemsList = $(this).find('[data-menu="anchor-item"]');
      const $title = $itemsList.find('h2').not('.product-details-article__title');
      const $list = $(this).find('.sticky-menu');
      const $listPlaceholder = $list.find('.sticky-menu__holder');
      const $topPos = $(this).position().top;
      const $productDetails = $(this).find('.product-details');

      if ($productDetails.length) {
        $(this).addClass('main--with-product-details');
      }

      setTimeout(function () {
        $list.css('top', $topPos + 1);
      }, 250);

      if ($title.length > 0) {
        const interMenu = document.createElement('ul');
        $(interMenu).addClass('anchor-menu');
        $(interMenu).appendTo($listPlaceholder);

        $itemsList.each((i, item) => {
          const li = document.createElement('li');
          const a = document.createElement('a');

          $(li).addClass('anchor-menu-item');
          a.textContent = $(item).find('h2').not('.product-details-article__title').text();
          let $id = '';

          if ($(item).attr('id') === undefined) {
            $(item).attr('id', (`item${i + 1}`));
            $id = $(item).attr('id');
          } else {
            $id = $(item).attr('id');
          }

          $(a).attr('href', (`#${$id}`));
          li.appendChild(a);
          interMenu.appendChild(li);
        });

        $('.anchor-menu li').first().find('a').addClass('active-menu');

        $(document).on('scroll', function () {
          const $windowPos = $(this).scrollTop();

          $('.anchor-menu-item a').each(function (event) {
            const $id = $(this).attr('href');
            if ($windowPos >= $($id).offset().top - $topPos - 52) {
              $('.anchor-menu-item a').not(this).removeClass('active-menu');
              $(this).addClass('active-menu');
            }
          });
        });
        const menuLink = interMenu.querySelectorAll('.anchor-menu-item a');
        $(menuLink).click(function animateBody() {
          const $target = $($.attr(this, 'href'));
          $(menuLink).removeClass('active-menu');
          $(this).addClass('active-menu');
          $bodyHtml.stop().animate({ scrollTop: $target.offset().top - $topPos - 51 }, 500);
          return false;
        });
      } else {
        $listPlaceholder.hide();
      }
    },
    detach: function (context, settings) {}
  });
})(jQuery);
