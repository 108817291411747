(function ($) {
  DJ.addBehavior('resourcesAccordion', '.view-display-id-technical_catalog', {
    attach: function () {
      const $accordClcTitle = $(this).find('.download-block__subcategory-name');
      const $accordParent = $(this).find('.download-block__wrapper');
      const toggleActive = 'active';

      $accordClcTitle.click(function () {
        $(this).parent($accordParent).toggleClass(toggleActive);
        return false;
      });
    }
  });
})(jQuery);
