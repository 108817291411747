(function ($) {
  DJ.addBehavior('filterItemsAccordion', '.js-product-filter-item-accordion', {
    attach: function () {
      const item = this;
      const itemTitle = item.querySelector('.product-filter-item__title');
      const itemToggleClass = 'product-filter-item--closed';

      if (!itemTitle) {
        return;
      }

      /**
       * Open/close item on the title click.
       */
      itemTitle.addEventListener('click', () => {
        item.classList.toggle(itemToggleClass);
      });
    }
  });
})(jQuery);
