(function ($) {
  DJ.addBehavior('contactsScroll', '.map-contacts__inner', {
    attach: function () {
      const contacts = this;

      if (window.innerWidth > 1200) {
        jcf.replace(contacts, 'Scrollable');
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth > 1200) {
          jcf.replace(contacts, 'Scrollable');
        } else {
          jcf.destroy(contacts);
        }
      });
    }
  });
})(jQuery);
