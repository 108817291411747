(function ($) {
  DJ.addBehavior('customVideo', 'video:not(.img-holder__video video', {
    attach: function () {
      const videoElement = this;
      const parentThumbnailClass = 'media-slider__thumbnails-item-inner';

      let options = {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
      };

      // Disable video if it's in thumbnails.
      if (videoElement.parentNode.classList.contains(parentThumbnailClass)) {
        options = {
          controls: [],
          clickToPlay: false,
          fullscreen: {
            enabled: false
          },
          keyboard: {
            focused: false
          }
        };
      }

      /**
       * Init player.
       */
      const player = new Plyr(videoElement, options);

      player.on('ready', (event) => {
        const plyrContainer = event.detail.plyr.elements.container;
        const widthToSet = videoElement.getAttribute('width');

        if (widthToSet) {
          plyrContainer.style.maxWidth = `${widthToSet}px`;
        }
      });

      // Pause video inside the slider when slide.
      $('.media-slider__slider').on('beforeChange', function () {
        player.pause();
      });
    }
  });
})(jQuery);
