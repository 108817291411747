(function ($) {
  DJ.addBehavior('headerMobileMenu', '.header-btn-menu', {
    attach: function () {
      const btnOpen = this;
      const headerNavHolder = document.querySelector('.header-menus');
      const headerOverlay = document.querySelector('.header-menus-overlay');
      const btnOpenClass = 'header-btn-menu--active';
      const headerOpenClass = 'header-menus--opened';
      const headerOverlayOpenClass = 'header-menus-overlay--active';

      if (btnOpen) {
        /**
         * Open header menu. The page is locked while the menu is open(html--locked).
         */
        btnOpen.addEventListener('click', () => {
          document.getElementsByTagName('html')[0].classList.toggle('html--locked');
          btnOpen.classList.toggle(btnOpenClass);

          if (headerOverlay && headerNavHolder) {
            headerOverlay.classList.toggle(headerOverlayOpenClass);
            headerNavHolder.classList.toggle(headerOpenClass);
            headerNavHolder.style.height = `${headerOverlay.offsetHeight - headerNavHolder.offsetTop}px`;
          }
        });

        /**
         * Close mobile menu on breakpoint change.
         */
        DJ.on('changeBreakpoint', function (e) {
          if (e.breakpoint === 'desktop' || e.breakpoint === 'desktopBig') {
            document.getElementsByTagName('html')[0].classList.remove('html--locked');
            btnOpen.classList.remove(btnOpenClass);

            if (headerOverlay && headerNavHolder) {
              headerOverlay.classList.remove(headerOverlayOpenClass);
              headerNavHolder.classList.remove(headerOpenClass);
              headerNavHolder.style.height = 'auto';
            }
          }
        });
      }
    }
  });
})(jQuery);
