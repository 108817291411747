(function ($) {
  DJ.addBehavior('headerSticky', '.header', {
    attach: function () {
      const header = this;

      /**
       * Add/remove sticky class depending on scrollTop.
       */
      const toggleSticky = () => {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        header.classList.remove('header--sticky-view');
        header.style.height = 'auto';

        if (st >= 0) {
          header.style.height = `${header.offsetHeight - 1}px`;
          header.classList.add('header--sticky-view');
        } else {
          header.style.height = 'auto';
          header.classList.remove('header--sticky-view');
        }
      };

      toggleSticky();

      window.addEventListener('resize', () => {
        toggleSticky();
      });

      window.addEventListener('scroll', () => {
        toggleSticky();
      }, false);
    }
  });
})(jQuery);
