(function ($) {
  DJ.addBehavior('langSelect', '.lang-select', {
    attach: function () {
      const langSelect = this;
      const dropDownButton = langSelect.querySelector('.lang-select__button');
      const activeLang = langSelect.querySelector('.is-active');
      const dropDown = langSelect.querySelector('.lang-select__list');
      const dropDownShowClass = 'lang-select__list--show';

      if (!dropDownButton && !dropDown) {
        return;
      }

      // Take text for the button from the active language.
      if (activeLang) {
        dropDownButton.textContent = activeLang.textContent;
      }

      /**
       * Open/close language dropdown.
       */
      const toggleDropDown = () => {
        dropDown.classList.toggle(dropDownShowClass);
      };

      langSelect.addEventListener('mouseover', toggleDropDown);
      langSelect.addEventListener('mouseout', toggleDropDown);
    }
  });
})(jQuery);
