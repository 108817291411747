(function ($) {
  DJ.addBehavior('mapReset', '.map-section-reset-map', {
    attach: function () {
      const fakeResetMapButton = this;

      // On click on the fake reset click on the real reset button.
      fakeResetMapButton.addEventListener('click', () => {
        const realResetButton = document.querySelector('[id*="reset-control"]');

        if (realResetButton) {
          realResetButton.click();
        }
      });
    }
  });
})(jQuery);
