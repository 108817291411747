(function ($) {
  DJ.addBehavior('imgWithTextBlock', '.img-data-block', {
    attach: function () {
      const holder = this;
      const image = holder.querySelector('img');

      if (!image) {
        holder.classList.add('img-data-block--no-image');
      }
    }
  });
})(jQuery);
