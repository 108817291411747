(function ($) {
  DJ.addBehavior('searchClear', '.js-search-clear', {
    attach: function () {
      const searchHolder = this;
      const searchInput = searchHolder.querySelector('.form-item input');
      const clearBtn = searchHolder.querySelector('.search-banner__btn-clear');
      const clearBtnShowClass = 'search-banner__btn-clear--show';

      if (!searchInput && !clearBtn) {
        return;
      }

      // On search input show/hide clear button.
      searchInput.addEventListener('input', () => {
        let opacityTimeout;

        if (searchInput.value.length > 0) {
          clearBtn.classList.add(clearBtnShowClass);
          clearBtn.style.opacity = '1';
        } else {
          clearBtn.style.opacity = '0';

          opacityTimeout = setTimeout(() => {
            clearBtn.classList.remove(clearBtnShowClass);
          }, 250);
        }

        clearTimeout(opacityTimeout);
      });

      // On click clear value in the search input and hide button.
      clearBtn.addEventListener('click', () => {
        searchInput.value = '';
        clearBtn.style.opacity = '0';

        setTimeout(() => {
          clearBtn.classList.remove(clearBtnShowClass);
        }, 250);
      });
    },
  });
})(jQuery);
