(function ($) {
  DJ.addBehavior('mobileFilters', '.js-mobile-filters', {
    attach: function () {
      const mainHolder = this;
      const openBtn = mainHolder.querySelector('.js-product-filters-mobile-btn');
      const closeBtn = mainHolder.querySelector('.js-product-filters-close-btn');
      const filtersHolder = mainHolder.querySelector('.product-filters');
      const filtersOpenedClass = 'product-filters--opened';

      if (!openBtn && !closeBtn && !filtersHolder) {
        return;
      }

      /**
       * Open mobile filters on the button click.
       */
      openBtn.addEventListener('click', () => {
        filtersHolder.classList.add(filtersOpenedClass);
      });

      /**
       * Close mobile filters on the button click.
       */
      closeBtn.addEventListener('click', () => {
        filtersHolder.classList.remove(filtersOpenedClass);
      });
    }
  });
})(jQuery);
