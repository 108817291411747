(function ($) {
  DJ.addBehavior('formsInit', '.product-filters, .contact-form', {
    attach: function () {
      const formWrapper = this;
      const checkboxes = formWrapper.querySelectorAll('input[type="checkbox"]');
      const select = formWrapper.querySelectorAll('select');
      const file = formWrapper.querySelectorAll('input[type="file"]');
      let optionWrapNative = false;

      if (formWrapper.classList.contains('contact-form')) {
        optionWrapNative = true;
      }

      /**
       * jcf init.
       */
      jcf.replace(checkboxes, 'Checkbox');
      jcf.replace(select, 'Select', {
        wrapNative: optionWrapNative,
        fakeDropInBody: false,
        useCustomScroll: false,
        maxVisibleItems: 5
      });
      jcf.replace(file, 'File', {
        buttonText: '',
        placeholderText: ''
      });

      /**
       * After ajax complete re-init checkboxes.
       */
      jQuery(document).ajaxComplete(() => {
        const elementsToUpdateHolder = document.querySelector('.contact-form');
        const elementsToUpdate = elementsToUpdateHolder.querySelectorAll('input[type="checkbox"]');

        jcf.replace(elementsToUpdate, 'Checkbox');
      });

      /**
       * On submit scroll to the invalid element taking into account header height.
       */
      const delay = 0;
      const form = formWrapper.querySelector('form');
      const headerElement = document.querySelector('.header');
      const headerHeight = headerElement ? headerElement.offsetHeight : 0;
      const invalidInFocusClass = 'invalid--in-focus';

      if (form) {
        form.addEventListener('invalid', function (e) {
          $(e.target).addClass('invalid');

          const elementWithError = formWrapper.querySelector('.invalid');

          if (!elementWithError.classList.contains(invalidInFocusClass)) {
            elementWithError.classList.add(invalidInFocusClass);

            if ($(elementWithError).closest('.jcf-select')) {
              $(elementWithError).closest('.jcf-select').addClass(invalidInFocusClass);
            }
          }

          $('html, body').animate({ scrollTop: $(elementWithError).offset().top - (headerHeight + 20) }, delay);
        }, true);

        /**
         * On form element blur remove invalid classes.
         */
        form.addEventListener('blur', function (e) {
          $(e.target).removeClass('invalid');
          $(e.target).removeClass(invalidInFocusClass);

          if ($(e.target).closest('.jcf-select')) {
            $(e.target).closest('.jcf-select').removeClass(invalidInFocusClass);
          }
        }, true);
      }
    }
  });
})(jQuery);
